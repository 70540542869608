import style from "./style.module.css";
// import Nav from "../../components/nav";
// import Aside from "../../components/aside";
import Header from "../../components/header";
// import About from "../../components/about";
// import Skills from "../../components/skills";
// import Projects from "../../components/projects";
// import Contact from "../../components/contact";
// import Footer from "../../components/footer";

const Home = () => {
  return (
    <div className={style.container}>
      {/* <Nav />
      <Aside /> */}
      <Header />
      {/* <About />
      <Skills />
      <Projects />
      <Contact />
      <Footer /> */}
    </div>
  );
};

export default Home;
