import { useState } from "react";
import style from "./style.module.css";
import classnames from "classnames";
import Nav from "../nav";
import Socialmedia from "../socialmedia";

const Header = () => {
  const [isNegative, setNegative] = useState(false);

  return (
    <header
      className={classnames(style.header, { [style.negative]: isNegative })}
    >
      <div>
        <Nav />
      </div>
      <div className={style.left}>
        <h1
          className={style.title}
          onMouseOver={() => {
            setNegative(true);
          }}
        >
          Code
        </h1>
        <h2 className={style.subtitle}>Frontend</h2>
      </div>
      <div className={style.right}>
        <h1
          className={style.title}
          onMouseOver={() => {
            setNegative(false);
          }}
        >
          Ting
        </h1>
        <h2 className={style.subtitle}>Developer</h2>
      </div>
      <div>
        <Socialmedia />
      </div>
    </header>
  );
};

export default Header;
