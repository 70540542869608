import style from "./style.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXing,
  faLinkedinIn,
  faGithub,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const Socialmedia = () => {
  return (
    <div className={style.socials}>
      <ul className={style.list}>
        <li>
          <a
            className={style.tooltip}
            href="https://www.xing.com/profile/Jenny_Hong6/cv"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faXing} className={style.tooltipicon} />
            <span className={style.tooltiptext}>xing</span>
          </a>
        </li>
        <li>
          <a
            className={style.tooltip}
            href="https://www.linkedin.com/in/codeting/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={faLinkedinIn}
              className={style.tooltipicon}
            />
            <span className={style.tooltiptext}>linkedin</span>
          </a>
        </li>
        <li>
          <a
            className={style.tooltip}
            href="https://github.com/code-ting"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faGithub} className={style.tooltipicon} />
            <span className={style.tooltiptext}>github</span>
          </a>
        </li>
        <li>
          <a
            className={style.tooltip}
            href="https://twitter.com/codeting"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faTwitter} className={style.tooltipicon} />
            <span className={style.tooltiptext}>twitter</span>
          </a>
        </li>
        <li>
          <a
            className={style.tooltip}
            href="https://www.instagram.com/code.ting/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} className={style.tooltipicon} />
            <span className={style.tooltiptext}>instagram</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Socialmedia;
