import style from "./style.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faCode,
  faHome,
  faRocket,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

const Nav = () => {
  return (
    <nav className={style.nav}>
      <ul className={style.list}>
        <li>
          <a className={style.tooltip} href="#home" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faHome} className={style.tooltipicon} />
            <span className={style.tooltiptext}>home</span>
          </a>
        </li>
        <li>
          <a className={style.tooltip} href="#home" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faUser} className={style.tooltipicon} />
            <span className={style.tooltiptext}>about</span>
          </a>
        </li>
        <li>
          <a className={style.tooltip} href="#home" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faRocket} className={style.tooltipicon} />
            <span className={style.tooltiptext}>skills</span>
          </a>
        </li>
        <li>
          <a className={style.tooltip} href="#home" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faCode} className={style.tooltipicon} />
            <span className={style.tooltiptext}>projects</span>
          </a>
        </li>
        <li>
          <a className={style.tooltip} href="#home" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faEnvelope} className={style.tooltipicon} />
            <span className={style.tooltiptext}>contact</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
